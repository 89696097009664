/* GENERAL */

body * {
  font-family: 'Noto Sans', sans-serif;
}

.mb-4 {
  margin-bottom: 30px !important;
}

.main-screen {
  background: #e8eaf2;
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.floating-label {
  position: relative;
  margin-bottom: 20px;
}

.floating-label input {
  width: 100%;
  padding: 7px 0;
  padding-top: 25px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #0c1534;
  color: #03050d;
  font-size: 18px;
}

.floating-label label {
  position: absolute;
  top: 20px;
  left: 0px;
  color: #67696d;
  font-size: 14px;
  pointer-events: none;
  transition: 0.2s ease all;
  font-size: 18px;
}

.floating-label input:focus+label,
.floating-label input:not(:placeholder-shown)+label {
  top: 0px;
  left: 0px;
  font-size: 12px;
  color: #67696d;
  font-size: 14px;
}

.floating-label input:focus {
  outline: none;
}

.floating-label .filled {
  top: -10px;
  left: 10px;
  font-size: 12px;
  color: #333;
}

.btn-primary {
  width: 100%;
  padding: 15px 30px;
  background-color: #0c1534;
  color: #e8eaf2;
  border: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 5px;
  text-align: center;
}

.btn-floating {
  width: 100%;
  padding: 15px 30px;
  background-color: transparent;
  color: #0c1534;
  border: 2px solid black;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 5px;
  text-align: center;
}

.btn-secondary {
  width: 100%;
  padding: 15px 30px;
  background-color: transparent;
  color: #0c1534;
  border: 2px solid #0c1534;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 5px;
  text-align: center;
}

.btn-microsoft-login{
  width: 100%;
  padding: 8px 30px;
  background-color: #2C2C2E;
  color: #e8eaf2;
  border: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 5px;
  text-align: center;
}

.btn-microsoft-login-user{
  width: 100%;
  padding: 15px 30px;
  background-color: #2C2C2E;
  color: #e8eaf2;
  border: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 5px;
  text-align: center;
}

.div-microsoft-title{
  margin-top: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.btn-microsoft-logout{
  width: 50%;
  padding: 8px 30px;
  background-color: #2C2C2E;
  color: #e8eaf2;
  border: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 5px;
  text-align: center;
}

.btn-microsoft-remove{
  width: 50%;
  padding: 8px 30px;
  background-color: #b93737;
  color: #e8eaf2;
  border: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 5px;
  text-align: center;
}

.btn-primary.blocked-btn {
  background-color: #555;
}

.btn-margin-top{
  margin-top: 25px;
}

.btn-primary-circle {
  width: 36px;
  height: 36px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #0c1534;
  color: #e8eaf2;
  border: 0;
}

.btn-primary-circle.btn-delete {
  background-color: #990000;
}

.btn-valid{
  background-color: #1DB954;
}

.btn-pending-valid{
  background-color: #FFAA33;
}

.btn-error-valid{
  background-color: #990000;
}

.btn-edit-date{
  border: 0;
  background: transparent;
  color: #0c1534;
}

.success-background{
  background-color: #1DB954;
}

.error-message {
  color: #990000;
  font-size: 14px;
  position: absolute;
  margin-top: 12px;
  font-weight: 600;
}

.error-message2 {
  color: #990000;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  margin-bottom: 0;
  text-align: center;
}

.error-message2 a {
  color: #0c1534;
  text-decoration: none;
}

.warning-icon{
  color: #990000;
  font-size: 50px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.card {
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.blue-card {
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  background-color: #0c1534;
  color: #e8eaf2;
  margin-bottom: 30px;
}

.blue-card-label {}

.blue-card-value {
  font-size: 60px;
  font-weight: 600;
  height: 90px;
}

.blue-card-small {
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  background-color: #0c1534;
  color: #e8eaf2;
  margin-bottom: 20px;
}

.blue-card-label-small {
  font-size: 12px;
}

.blue-card-value-small {
  font-size: 16px;
  font-weight: 600;
}

.details-modal .modal-content {
  padding-top: 60px;
}

.users-datagrid .MuiDataGrid-root {
  padding: 0;
  margin-bottom: 30px;
}

/* LOGIN */

.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box {
  width: calc(100vw - 40px);
  max-width: 500px;
  margin: 0 20px;
  background-color: #fff;
  padding: 25px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
}

.login-logo {
  width: 200px;
  object-fit: contain;
  margin: 0 auto;
  margin-bottom: 20px;
}

.login-forgot {
  text-align: right;
  margin-bottom: 30px;
  margin-top: -10px;
}

.login-forgot a {
  text-decoration: none;
  color: #1e3582;
}

/* MENU */

.menu-container {
  width: 250px;
  min-width: 250px;
  height: 100vh;
  background-color: #0c1534;
}

.menu-bell{
  align-items: center;
  text-align: center;
  padding-top: 30px;
}

.menu-logo-container {
  background-color: #ffffff;
  padding: 30px;
  box-shadow: inset 2px -5px 10px -6px #000000;
}

.menu-logo {
  width: 100%;
  object-fit: contain;
}

.menu-list {
  padding: 25px;
}

.menu-item {
  color: #e8eaf2;
  padding: 5px 10px;
  margin: 10px 0;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
}

.menu-item a {
  color: #e8eaf2;
  text-decoration: none;
}

.menu-item svg{
  width: 18px;
}

.menu-item.active {
  color: #0c1534;
  background-color: #e8eaf2;
  font-weight: 600;
}

.menu-item.active a {
  color: #0c1534;
}

.menu-item span {
  margin-left: 10px;
}

.btn-logout {
  position: absolute;
  bottom: 15px;
  left: 30px;
}

/* DASHBOARD */

.dashboard-container {
  height: 100vh;
  flex: 1;
  padding: 30px 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.access-denied-container {
  height: 100vh;
  flex: 1;
  padding: 30px 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.btn-create-appointment {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-floating-right {
  margin-bottom: 30px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  right: 0;
}

.btn-create-appointment .btn-primary {
  width: fit-content
}

.btn-create-appointment .btn-primary svg {
  margin-right: 10px;
}

.btn-center{
  display: block;
  margin: auto;
}

.table-container {
  padding: 0px;
  margin: 0 -20px;
}

.table-container .table {
  margin: 0;
}

.MuiDataGrid-virtualScrollerContent {
  min-height: 40px;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: 0;
}

.table-container {
  padding: 0;
}

.MuiDataGrid-root {
  border-radius: 20px !important;
  border: 0 !important;
  overflow: hidden;
}

.dashboard-container .MuiDataGrid-footerContainer {
  display: none;
}

.details-modal .MuiDataGrid-footerContainer {
  display: none;
}

.MuiDataGrid-selectedRowCount.css-de9k3v-MuiDataGrid-selectedRowCount {
  visibility: hidden;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader,
.MuiDataGrid-root .MuiDataGrid-cell {
  padding: 0 20px !important;
}

.view-more-btn-container {
  text-align: center;
  margin-bottom: 20px;
}

.view-more-btn-container .btn-primary {
  width: fit-content;
}

.table-actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.seats-container,
.km-container {
  height: 20px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}

.seats-container svg {
  margin-left: 5px;
  width: 15px;
  font-size: 16px;
}

.km-container span {
  font-size: 10px;
  font-weight: bold;
  margin-left: 5px;
  width: 15px;
  line-height: 1;
}

.table-actions-container .btn-primary-circle {
  margin-left: 10px;
}

.table-date-container {
  line-height: 1.2;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 600;
}

.MuiDataGrid-root {
  color: #0c1534 !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.notification-readed{
  font-size: small;
}

.MuiDataGrid-main {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.MuiDataGrid-root {
  padding: 0 20px;
  padding-bottom: 20px;
}

.MuiDataGrid-toolbarContainer {
  justify-content: flex-end;
  margin-bottom: 20px;
}

.MuiDataGrid-toolbarQuickFilter {
  width: 286px !important;
}

.MuiDataGrid-toolbarQuickFilter .MuiInput-underline:before,
.MuiInputBase-root::after {
  border-bottom: 2px solid #0c1534 !important;
}

.MuiTablePagination-root .MuiTablePagination-input,
.MuiTablePagination-root .MuiTablePagination-selectLabel {
  display: none !important;
}


.MuiTablePagination-displayedRows {
  font-weight: bold !important;
  color: #0c1534;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: transparent !important;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected.registered-row {
  background-color: #e6e7ea !important;
}

.registered-row {
  background-color: #e6e7ea;
  /* Cor para linhas registradas */
}



/* MODAL */

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 35% !important;
  max-width: calc(100vw - 20px);
  position: relative;
  overflow-y: auto;
  max-height: 85%;
}

.modal-content-details {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 50% !important;
  max-width: calc(100vw - 20px);
  position: relative;
  overflow-y: auto;
  max-height: 85%;
}

/* Quando a largura da tela for de 1024px ou menor, ajusta o width para 70% */
@media (max-width: 1600px) {
  .modal-content-details {
    width: 70% !important; /* Ajusta o width para 70% */
  }
}

/* Quando a largura da tela for de 1024px ou menor, ajusta o width para 70% */
@media (max-width: 1200px) {
  .modal-content-details {
    width: 90% !important; /* Ajusta o width para 70% */
  }
}


/* width */
.modal-content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modal-content::-webkit-scrollbar-thumb {
  background: #0c1534;
}

/* Handle on hover */
.modal-content::-webkit-scrollbar-thumb:hover {
  background: #0c1534d0;
}

.close {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.modal .modal-content {
  width: 500px;
}

.modal form {
  margin-top: 30px;
}

.modal input {
  width: 100%;
}

.modal .modal-footer {
  padding: 0;
  border: 0;
}

.modal .modal-footer .btn-primary {
  margin: 0;
  margin-top: 20px;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}


div[data-testid='tail-spin-loading'] {
  justify-content: center;
}

.confirm-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-dialog {
  background: white;
  padding: 20px;
  border-radius: 4px;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.confirm-dialog-buttons {
  margin-top: 20px;
  display: flex;
}

.confirm-dialog-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  width: 50%;
  font-size: 14px;
}

.confirm-dialog-buttons button:first-child {
  margin-left: 0;
}
.confirm-dialog-buttons button:last-child {
  margin-right: 0;
}


/* TRIPS */

.trips-container {
  height: 100vh;
  flex: 1;
  padding: 30px 0;
  overflow-y: auto;
  overflow-x: hidden;
}

/* SETTINGS */

.settings-container {
  height: 100vh;
  flex: 1;
  padding: 30px 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.floating-label select {
  width: 100%;
  border: 0;
  border-bottom: 2px solid #0c1534;
  padding: 7px 0;
  padding-top: 25px;
  font-size: 18px;
}

.floating-label .select-label {
  font-size: 14px;
  left: 0;
  top: 0;
}

.form-separator {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 40px;
}

.edit-profile-form .floating-label input{
  background-color: transparent;
}

.edit-profile-form .floating-label textarea{
  background-color: transparent;
}

.floating-label textarea{
  width: 100%;
  padding: 7px 0;
  padding-top: 25px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #0c1534;
  color: #03050d;
  font-size: 18px;
}

.edit-profile-form .btn-container{
  text-align: end;
  margin-top: 20px;
}

.edit-profile-form .btn-container .btn-primary{
  width: fit-content;
}

.edit-profile-form .form-separator{
  margin-bottom: 20px;
}

/* ROLES */

.permission-list{
  margin-top: 40px;
  margin-bottom: 20px;
}

.permission-list .permission-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 10px;
  padding: 10px;
}

.permission-list .permission-row p{
  margin: 0;
}

.permission-add{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permission-add .floating-label{
  width: calc(100% - 70px);
}

.permission-add .btn-primary-circle{
  margin-right: 10px;
}

/* MOBILE */

@media (max-width: 768px) {
  .main-screen {
    display: block;
  }

  .btn-primary {
    padding: 10px 20px;
    font-size: 14px;
  }

  .menu-top-mobile {
    background-color: #0c1534;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .menu-logo {
    height: 40px;
    width: 90px;
    object-fit: contain;
  }

  .menu-container {
    position: absolute;
    top: 0;
    left: -250px;
    z-index: 9;
    transition: all 0.5s ease-in-out;
  }

  .menu-container.open {
    left: 0;
  }

  .menu-overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    right: 0;
    background-color: #000000;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
  }

  .menu-overlay.open {
    opacity: 0.4;
    z-index: 9;
  }

  .menu-logo-container {
    height: 80px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }

  .btn-open-menu {
    border: 0;
    background: transparent;
    color: #e8eaf2;
    font-size: 24px;
    z-index: 99;
  }

  .dashboard-container,
  .settings-container {
    padding-top: 100px;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiDataGrid-cell {
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiDataGrid-menuIcon {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
  }

  .MuiDataGrid-iconButtonContainer {
    display: none !important;
  }

  .MuiDataGrid-columnHeaderTitleContainerContent {
    overflow: visible !important;
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader,
  .MuiDataGrid-root .MuiDataGrid-cell {
    padding: 0 10px !important;
  }
}

.no-data-message {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: calc(100vh - 100px); /* Ajuste a altura conforme necessário para deixar espaço para outros elementos */
  font-size: 1.5rem; /* Tamanho da fonte opcional */
  color: #0c1534; /* Cor opcional para o texto */
}

.chat-container {
  border: 1px solid #ccc;
  padding: 10px;
  max-width: 400px;
  margin: auto;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}

.message {
  margin-bottom: 5px;
}

.message-input {
  display: flex;
}

input {
  flex: 1;
  padding: 5px;
  margin-right: 5px;
}

#chat-list {
  position: fixed;
  bottom: 6rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 25rem;
  max-height: 30rem;
  color: #0c1534;
  background-color: white;
  border-radius: 1.25rem;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

#chat-list .list-group {
  overflow-y: auto;
}

#chat-list .btn-primary {
  width: auto;
  border-radius: 0;
}

.chat-message-1, .chat-message-2 {
  display: inline-flex;
  max-width: 15rem;
  padding: 0.4rem 1rem;
  border-radius: 0.75rem;
}

.chat-message-1 {
  color: #0c1534;
  background-color: #e8eaf2;
}

.chat-message-2 {
  color: white;
  background-color: #0c1534;
}

#message-field {
  outline: none;
  border: none;
  border-top: 1px solid #e6e7ea;
}

.btn-chat {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 4rem;
  height: 4rem;
  padding: 0;
  color: white;
  background-color: #0c1534;
  font-size: 1.5rem;
  border-radius: 50%;
  border: none;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.35);
}

#chat-card .btn-close {
  width: 0.5rem;
  height: 0.5rem;
}

#chat-card .btn-default {
  color: #a0a0a0;
  padding: 0 0 0.5rem;
  height: 1rem;
  margin-right: 0.75rem;
  margin-bottom: 0.35rem;
}

#chat-card .btn-default .fa-lg {
  vertical-align: 0;
}

.message-sender {
  color: #a0a0a0;
  font-size: 0.75rem;
}